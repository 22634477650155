// **** TODO ****
//
// Deploy


import React from "react"
import Notifications, { notify } from "react-notify-toast"
import { MAIL_API_KEY } from "gatsby-env-variables"


import Layout from "../components/layout"
import Image from "../components/image"
import Logo from "../components/logo"
import SEO from "../components/seo"
import BGImg from "../components/bg_image"
import MainImg from "../images/Illustration.png"

//import "../components/holding.scss"
import "../components/holding.css"
//BHK3a_HxqzCt%Hp
//console.log(MAIL_API_KEY)
//const MAIL_API_KEY = process.env.GATSBY_SEND_IN_BLUE_KEY
//const MAIL_API_KEY = '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'
export default class IndexPage extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    driver: false,
    url: "https://api.sendinblue.com/v3/contacts",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleCheck = event => {
    const target = event.target
    const value = target.checked
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  test = event => {}

  handleSubmit = event => {
    event.preventDefault()
    //console.log(MAIL_API_KEY);
    let myColor = {
      background: "#3A94E9",
      text: "#FFFFFF",
      textSize: "10px",
      boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.16)",
    }
    fetch(this.state.url, {
      method: "POST",
      headers: {'accept': "application/json", 'content-type': "application/json", 'api-key':MAIL_API_KEY},
      body: JSON.stringify({
        email: this.state.email,
        attributes:{
          firstName: this.state.firstName,
          surname: this.state.lastName,
          lastName: this.state.lastName,
          driver: this.state.driver,
        }
      }),
    })
      .then(res => res.json())
      .then(data => {
        let keyList = Object.keys(data);
        if(keyList.includes('id')){
          notify.show(
            "Thank you for signing up to Pata!",
            "custom",
            3000,
            myColor
          );
          this.setState({
            firstName: "",
            lastName: "",
            email: "",
            driver: false
          })
        }
        else{
          notify.show(
            "Could not sign you up, please try again later",
            "error",
            3000
          )
        }
        
        //console.log(data)
      })
      .catch(err => {
        notify.show(
          "Could not sign you up, please try again later",
          "error",
          3000
        )
        //console.log(err);
      })
  }

  render() {
    const Social = () => {
      return (
        <div className="social-group">
          <div className="social-icons">
            <a href="https://www.facebook.com/gopata.co">
              <div className="icons facebook"></div>
            </a>
          </div>

          <div className="social-icons">
            <a href="https://www.instagram.com/gopata.co/">
              <div className="icons instagram"></div>
            </a>
          </div>

          <div className="social-icons">
            <a href="https://twitter.com/gopata_co">
              <div className="icons twitter"></div>
            </a>
          </div>

          <div className="social-icons">
            <a href="https://wa.me/256781368518">
              <div className="icons whatsapp"></div>
            </a>
          </div>
        </div>
      )
    }

    const SocialMobile = () => {
      return (
        <div className="social-group-mobile">
          <div className="social-icons-mobile">
            <a href="https://www.facebook.com/gopata.co">
              <div className="icons-mobile facebook"></div>
            </a>
          </div>

          <div className="social-icons-mobile">
            <a href="https://www.instagram.com/gopata.co/">
              <div className="icons-mobile instagram"></div>
            </a>
          </div>

          <div className="social-icons-mobile">
            <a href="https://twitter.com/gopata_co">
              <div className="icons-mobile twitter"></div>
            </a>
          </div>

          <div className="social-icons-mobile">
            <a href="https://wa.me/256781368518">
              <div className="icons-mobile whatsapp"></div>
            </a>
          </div>
        </div>
      )
    }
    return (
      <Layout>
        <Notifications />
        <SEO title="Pata" />
        <div className="d-none d-md-block">
          <div className="container">
            <div className="left-bar">
              <div className="logo">
                <Logo />
              </div>

              <h1 className="header-text">
                Coming soon... A better way to travel in Uganda
              </h1>
              <p className="main-text">
                Offering you a safe and convenient way to hire drivers
                on-demand, for as long as you need.
              </p>
              <div className="main-image">
                <img src={MainImg} />
              </div>
            </div>
            <div className="bg-image">
              <BGImg />
            </div>
            <div className="right-bar">
            <Social />
              <div className="form-box">
                <p className="form-header">Sign up with Pata today</p>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control text-box"
                      id="first_name"
                      placeholder="First Name"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                      required
                    />
                    <input
                      type="text"
                      className="form-control text-box"
                      id="surname"
                      placeholder="Surname"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleInputChange}
                      required
                    />
                    <input
                      type="email"
                      className="form-control text-box"
                      id="exampleInputEmail1"
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      required
                    />
                    <div className="checkbox-group">
                      <span className="driver-text">Want to be a driver?</span>
                      <input
                        type="checkbox"
                        name="driver"
                        value={this.state.driver}
                        onChange={this.handleCheck}
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn buttons">
                    SIGN UP
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Responsive Version */}
        <div className="d-md-none">
          <div className="container-mobile">
            <div className="logo-mobile">
              <Logo />
            </div>
            <center>
              <h1 className="header-text-mobile">
                Coming soon... A better way to travel in Uganda
              </h1>
              <p className="main-text-mobile">
                Offering you a safe and convenient way to hire drivers
                on-demand, for as long as you need.
              </p>

              <div className="main-image-mobile">
              <img src={MainImg} />
              </div>
            </center>
            <SocialMobile />

            <div className="bg-image-mobile">
              <BGImg />
            </div>
            <div className="form-box-mobile">
              <p className="form-header-mobile">Sign up with Pata today</p>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control text-box-mobile"
                    id="first_name"
                    placeholder="First Name"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    required
                  />
                  <input
                    type="text"
                    className="form-control text-box-mobile"
                    id="surname"
                    placeholder="Surname"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    required
                  />
                  <input
                    type="email"
                    className="form-control text-box-mobile"
                    id="exampleInputEmail1"
                    placeholder="Email Address"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required
                  />
                  <div className="checkbox-group">
                    <span className="driver-text-mobile">
                      Want to be a driver?
                    </span>
                    <input
                      type="checkbox"
                      name="driver"
                      value={this.state.driver}
                      onChange={this.handleCheck}
                    />
                  </div>
                </div>
                <button type="submit" className="btn buttons-mobile">
                  SIGN UP
                </button>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
